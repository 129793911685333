
import config from 'react-global-configuration';

const request = (options) => {
    
        const headers = new Headers({
            'Content-Type': 'application/json',
        })      
    
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        return fetch(options.url)
        .then(response => 
            response.json().then(json => {
                if(!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
    };


    const postrequest = (options) => {
    
        const headers = new Headers({
            'Content-Type': 'application/json',
        })
        
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        return fetch(options.url,options)
        .then(response => 
            response.json().then(json => {
                if(!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
    };

    const postrequest4 = (options) => {
    
        const headers = new Headers({
            'Content-Type': 'application/json',
        })
        
     
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        return fetch(options.url,options)
        .then(response => 
            response.json().then(json => {
                if(!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
    };

    const postrequest2 = (options) => {
    
        const headers = new Headers({
            'Accept': "application/json",           
        }); 
    
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        return fetch(options.url,options)
        .then(response => 
            response.json().then(json => {
                if(!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
    };


    const postrequest3 = (options) => {
    
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': "application/json",
        })
        
    
    
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        return fetch(options.url,options)
        .then(response => 
            response.json().then(json => {
                if(!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
    };



    export function  getconfigurlmode(){
        let cnfpare = '';
        let sitemode = config.get('sitemode');
        if(sitemode =="dev"){
          cnfpare = config.get('devurl');
        } 
        if(sitemode =="uat"){
          cnfpare = config.get('uaturl');
        } 
        return cnfpare;
      }
 
//const sitemod = getconfigurlmode();
//function to return meny type
export function getmenubytype(mentype) {
        let cinpage = getconfigurlmode();
        return request({
                url: cinpage.getmenuurl + mentype,
                method: 'GET'
        });
}

export function userlogin(loginRequest) {
        let cinpage = getconfigurlmode();
        return postrequest({
            url: cinpage.loginurl,
            method: 'POST',
            body: JSON.stringify(loginRequest),
        });
    }

export function getloggeduser(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.getloggeduser,
                method: 'POST',
                body: JSON.stringify(data),
        });

    }
    export function getalluser(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.getalluser,
                method: 'POST',
                body: JSON.stringify(data),
            
        });

    }
        export function fnactiveuser(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.activeuser,
                method: 'POST',
                body: JSON.stringify(data),
        });

    }
    export function fnsearchbyemail(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.searchbyemail,
                method: 'POST',
                body: JSON.stringify(data),
        });

    }   export function getalllistorder(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.listuserorder,
                method: 'POST',
                body: JSON.stringify(data),
        });
        
    }
    export function userregister(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.userregister,
                method: 'POST',
                body: JSON.stringify(data),
        });}
        export function fngetschedulelist(data) {       

            let cinpage = getconfigurlmode();
                    
                return postrequest({
                    url: cinpage.getdatefoodschedule,
                    method: 'POST',
                    body: JSON.stringify(data),
            });}
            export function fnlistitem(data) {       

                let cinpage = getconfigurlmode();
                        
                    return postrequest({
                        url: cinpage.listitem,
                        method: 'POST',
                        body: JSON.stringify(data),
                });}
                export function fngetuserorder(data) {       

                    let cinpage = getconfigurlmode();
                            
                        return postrequest({
                            url: cinpage.getuserorder,
                            method: 'POST',
                            body: JSON.stringify(data),
                    });
                }
                // export function getuser(data) {       

                //     let cinpage = getconfigurlmode();
                            
                //         return postrequest({
                //             url: cinpage.getuser,
                //             method: 'POST',
                //             body: JSON.stringify(data),
                //     });
                // }
                
                export function fnaddnewclient(data) {       

                    let cinpage = getconfigurlmode();
                            
                        return postrequest({
                            url: cinpage.addnewclient,
                            method: 'POST',
                            body: JSON.stringify(data),
                    });
                }  
                export function fndeleteclient(data) {       

                    let cinpage = getconfigurlmode();
                            
                        return postrequest({
                            url: cinpage.deleteclient,
                            method: 'POST',
                            body: JSON.stringify(data),
                    });
                }  
                export function fngetclientdetails() {
                    let cinpage = getconfigurlmode();
                    return request({
                            url: cinpage.getclientdetails,
                            method: 'GET'
                    });
            }
            export function fnaddnewsystem(data) {       

                let cinpage = getconfigurlmode();
                        
                    return postrequest({
                        url: cinpage.addnewsystem,
                        method: 'POST',
                        body: JSON.stringify(data),
                });
            }
            export function fngetsystem(data) {       

                let cinpage = getconfigurlmode();
                        
                    return postrequest({
                        url: cinpage.getsystemdetails,
                        method: 'POST',
                        body: JSON.stringify(data),
                });
            }
            export function fndeletesystem(data) {       

                let cinpage = getconfigurlmode();
                        
                    return postrequest({
                        url: cinpage.deletesystems,
                        method: 'POST',
                        body: JSON.stringify(data),
                });
            }  
            export function fnaddnewinvoice(data) {       

                let cinpage = getconfigurlmode();
                        
                    return postrequest({
                        url: cinpage.savebtinvoice,
                        method: 'POST',
                        body: JSON.stringify(data),
                });
            } 
       
        export function fndeleteinvoice(data) {       

            let cinpage = getconfigurlmode();
                    
                return postrequest({
                    url: cinpage.deleteinvoice,
                    method: 'POST',
                    body: JSON.stringify(data),
            });
        }  
        export function fngetinvoicedetails(data) {       

            let cinpage = getconfigurlmode();
                    
                return postrequest({
                    url: cinpage.getinvoicelist,
                    method: 'POST',
                    body: JSON.stringify(data),
            });
        }  

        export function fnaddpersondetails(data) {       

            let cinpage = getconfigurlmode();
                    
                return postrequest({
                    url: cinpage.addspecimenprofile,
                    method: 'POST',
                    body: JSON.stringify(data),
            });
        }  

        export function fngetspecimendetails(data) {          
            let cinpage = getconfigurlmode();
            return postrequest({
                    url: cinpage.getspecimendetails,
                    method: 'POST',
                    body: JSON.stringify(data),
            });
    }

    export function fngetspecimenlistadmin(data) {
      
        let cinpage = getconfigurlmode();
        return postrequest({
                url: cinpage.getspecimenlistadmin,
                method: 'POST',
                body: JSON.stringify(data),
        });     
}

export function register(data) {       

    let cinpage = getconfigurlmode();
            
        return postrequest({
            url: cinpage.register,
            method: 'POST',
            body: JSON.stringify(data),
    });}

    export function getadminlist(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.getadminlist,
                method: 'POST',
                body: JSON.stringify(data),
        });}

        export function deleteadminuser(data) {       

            let cinpage = getconfigurlmode();
                    
                return postrequest({
                    url: cinpage.deleteuser,
                    method: 'POST',
                    body: JSON.stringify(data),
            });}

   export function resetpassword(data) {       

         let cinpage = getconfigurlmode();
                        
          return postrequest({
                 url: cinpage.resetpassword,
                 method: 'POST',
                 body: JSON.stringify(data),
     });}

    
   export function reviewstatusupdate(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.reviewstatusurl,
                method: 'POST',
                body: JSON.stringify(data),
    });}


    export function reviewgetcoments(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.reviewcommentsgeturl,
                method: 'POST',
                body: JSON.stringify(data),
        });

    }

    export function reviewrprtupdate(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.rptstatusupdate,
                method: 'POST',
                body: JSON.stringify(data),
    });}

    export function getallusers(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.getallusers,
                method: 'POST',
                body: JSON.stringify(data),
    });}

    export function getdefaultcomments(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.getdefaultcomments,
                method: 'POST',
                body: JSON.stringify(data),
    });}


    export function getuserslist(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.getuserslist,
                method: 'POST',
                body: JSON.stringify(data),
    });}

    export function generatereport(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.generatereport,
                method: 'POST',
                body: JSON.stringify(data),
    });}

    export function getreportlist(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.getreportlist,
                method: 'POST',
                body: JSON.stringify(data),
    });}


    export function sendrpttouser(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.sendrpttouser,
                method: 'POST',
                body: JSON.stringify(data),
    });}

    export function barcodevalidation(data) {       

        let cinpage = getconfigurlmode();
                       
         return postrequest({
                url: cinpage.barcodevalidate,
                method: 'POST',
                body: JSON.stringify(data),
    });}

    export function addstudent(data) {       

        let cinpage = getconfigurlmode();
                
            return postrequest({
                url: cinpage.addstudent,
                method: 'POST',
                body: JSON.stringify(data),
        });}


     export function getstudentdetailsbyid(data) {       

            let cinpage = getconfigurlmode();                    
                return postrequest({
                    url: cinpage.getuserdetailsbyid,
                    method: 'POST',
                    body: JSON.stringify(data),
            });
        
      }


   export function updatestudentprofile(data) {       

        let cinpage = getconfigurlmode();                    
            return postrequest({
                url: cinpage.updatestudent,
                method: 'POST',
                body: JSON.stringify(data),
        });
    
  }


  export function addupdatesubsription(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.addupdatesubscription,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getsubslists(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.subscriptionlists,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function getsubsdetailsbyid(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getsubsdetailsbyid,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function addupdatecourse(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.addupdatecourse,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getsubcriberslists(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getsubcriberslist,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function searchstudentsbyfilter(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.searchstudents,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function getcourselists(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.courselists,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getcourseparams(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.courseparsmas,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getcourseclasses(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.courseclasses,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function getassignestudentsbyclass(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getstudentsbyclass,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function assignstudent(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.assignstudent,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getcoursedetailsbyid(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getcoursedetailsbyid,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function assigncoursestudent(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.assigncoursestudent,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function assignattenancestudent(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.assignattendance,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function searchclassesbyfilter(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.searchclassesbyfilter,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function assigncourseclassstudent(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.assigncourseclassstudent,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getassignedstudentsbycourse(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getassignedstudentsbycourse,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getattendancereport(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getattendancereport,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function adduserattendancetoclass(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.userassignattendance,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function geuserbookedclassbyemail(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getuserbookedclasses,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getusergroups(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getusergroups,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function getuserlistbygroup(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getuserbygroup,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function addnewgroup(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.addnewgroup,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function assignusertogroup(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.userassigntouser,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function deleteusergroupbyid(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.delusergroup,
            method: 'POST',
            body: JSON.stringify(data),
    });

}

export function clonecourse(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.clonecourse,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function feecalcclassreport(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.feecalcclass,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function generatereportsummary(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.calculaterevenue,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function partnersummarydata(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.partnersummary,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function classsummarydata(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.classsummary,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function tutorsummarydata(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.tutorsummary,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function studentsummarydata(data) {       

    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.studentsummary,
            method: 'POST',
            body: JSON.stringify(data),
    });

}


export function getsubscriptiondata(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.subsstudentdetails,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function renewalsubscritoins(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.renewsubscription,
            method: 'POST',
            body: JSON.stringify(data),
    });
}



export function cancelsubscription(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.cancelsubscription,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function getcartdetails(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getcart,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function getpaymentintent(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getpaymentintent,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function getbookingconfirmation(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.bookingconfirmation,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function getsavedcardsstripe(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getsavedcards,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function getbookinghistory(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getbookinghistory,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function getsubscriptionlist(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getsubscriptionlist,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function addtocartcheckout(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.addtocart,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function useremailvalidate(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.emailvalidate,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function removecartitem(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.removecart,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function getcountrylists(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getcountrylist,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function getstuattreport(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.attendancereportbyMonth,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function getclassreprotbydate(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.classreportbyDay,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function updatecartitemindividual(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.updatecartitem,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function udpateitemquantity(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.upqtyitemurl,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function wpauthlogin(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.authwplogin,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function usdetailsbyid(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.udetailsbyemail,
            method: 'POST',
            body: JSON.stringify(data),
    });
}


export function calculateupgradefee(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.caclupgradefee,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function subcancontinueemail(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.subcontinueemail,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function getsmartconfiginfo(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getsmartconfig,
            method: 'POST',
            body: JSON.stringify(data),
    });
}
export function ureqresetpasswordtop(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.reqrestpassword,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function otpresetpassworduser(data) {    
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.otpresetpwd,
            method: 'POST',
            body: JSON.stringify(data),
    });
}
export function getcreditsdata(data){
    let cinpage = getconfigurlmode();                    
        return postrequest({
            url: cinpage.getcreditsdata,
            method: 'POST',
            body: JSON.stringify(data),
    });
}

export function validatetoken(loginRequest) {
    let cinpage = getconfigurlmode();
    return postrequest({
        url: cinpage.validate_token,
        method: 'POST',
        body: JSON.stringify(loginRequest),
        headers: {Accept: 'application/json',Authorization: 'Bearer '+loginRequest.validate}
    });
}
export function userlevelreq(data) {       

    let cinpage = getconfigurlmode();
                   
     return postrequest({
            url: cinpage.userlevelreq,
            method: 'POST',
            body: JSON.stringify(data),
});}