import React, { Component } from 'react';
import Resetpassword from '../../components/Resetpassword';
import { Layout, Menu, Icon,Breadcrumb,Avatar, Row, Col,Dropdown,Badge,notification, Spin } from 'antd';
import 'antd/dist/antd.css';
import './PageUserReset.css';
import logo from '../../images/xca-logo-black.png';
import "../../css/fonts.css";
import { ureqresetpasswordtop,otpresetpassworduser,getsmartconfiginfo } from '../../helpers/Apicommon';
import { CA_ACCESS_TOKEN,CA_LOOGED_EMAIL,CA_LOOGED_USER_DATA,sam_adminloggedstatus,BOOKING_SOURCE } from '../../constants';
import { Redirect } from "react-router-dom";
import { Helmet } from 'react-helmet';

const queryString = require('query-string');

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

class PageUserReset extends Component {

  constructor(props){
    super(props);
    this.state = {
      userlogin:{
          username: '',        
      },
      otpsendflag: false,
      resetpwddata:{
        emailotp: '', 
        epassword: '',
        confirmpassword: '',       
      },
      cardid:'',
      showcontinue: false,
      bookingsource: 'smta',
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
  }   
      this.handleinputonchange = this.handleinputonchange.bind(this);
      this.handleloginSubmit = this.handleloginSubmit.bind(this);
      this.handlepassworrest = this.handlepassworrest.bind(this); 
      this.handleresetpassword = this.handleresetpassword.bind(this); 
      this.handleredirectologinpage = this.handleredirectologinpage.bind(this);
  }

  handleredirectologinpage(){
    let cardid = this.state.cardid;

    if(cardid){
      this.props.history.push("/?cartid="+cardid);
    }else{
      this.props.history.push("/");
    }
    
  }

  clearallerrormesssage(){
    document.getElementById('error_email').innerHTML = '';  
    this.setState({logmessage: ''});    
  }

  clearallerrormesssage2(){
    document.getElementById('error_emailotp').innerHTML = ''; 
    document.getElementById('error_epassword').innerHTML = '';
    document.getElementById('error_confirmpassword').innerHTML = ''; 
    this.setState({logmessage: ''});    
  }

   handleinputonchange(event) {
      
     let elid = event.target.id;
     let cdata = this.state.userlogin;    
     if(elid == "loggemail"){  
       document.getElementById('error_email').innerHTML = ''; 
       cdata.username = event.target.value;            
     }          
     this.setState({userlogin: cdata});   
    
  }


  handlepassworrest(event) {
      
    let elid = event.target.id;
    let cdata = this.state.resetpwddata;    
    if(elid == "emailotp"){  
      document.getElementById('error_emailotp').innerHTML = ''; 
      cdata.emailotp = event.target.value;            
    }  
    
    if(elid == "epassword"){  
      document.getElementById('error_epassword').innerHTML = ''; 
      cdata.epassword = event.target.value;            
    } 

    if(elid == "confirmpassword"){  
      document.getElementById('error_confirmpassword').innerHTML = ''; 
      cdata.confirmpassword = event.target.value;            
    }     

    this.setState({resetpwddata: cdata});   
   
 }

 handleresetpassword() {

    this.clearallerrormesssage2();
    let cdata = this.state.resetpwddata;
    let cardid = this.state.cardid;
     let validateform = 1; 
     if(cdata.emailotp.trim()== ""){  
      document.getElementById('emailotp').focus();                   
      document.getElementById('error_emailotp').innerHTML='Please enter the OTP received in email';
      validateform = 0;
     }
     
     if(cdata.epassword.trim()== ""){  
      document.getElementById('epassword').focus();                   
      document.getElementById('error_epassword').innerHTML='Please enter the password';
      validateform = 0;
     }  

     if(cdata.epassword.trim() != cdata.confirmpassword.trim()){  
      document.getElementById('confirmpassword').focus();                   
      document.getElementById('error_confirmpassword').innerHTML='confirm password shoud match new password';
      validateform = 0;
     } 

     if(validateform == 1){
      this.setState({showloading: true}); 
      console.log(cardid);
      this.validateoptrestpassword();
    }
  

 }


 validateoptrestpassword(){ 
 
  document.getElementById('error_reset').innerHTML='';
  let cdata = this.state.resetpwddata;
  let udata  = this.state.userlogin;
  var data ={
    user_email: udata.username,      
    cartsource: this.state.bookingsource,
    email_otp: cdata.emailotp,
    new_password: cdata.epassword,
   }  

   otpresetpassworduser(data)
    .then(response => {  

        this.setState({showloading: false});  
        if(response.status == "success") {           
          this.setState({showcontinue: true});      
          document.getElementById('error_resetconfirm').innerHTML = 'Your password has been changed successfully. Pleae login again to complete the booking';          
        }else{
          this.setState({logmessage: ''});    
          document.getElementById('error_reset').innerHTML = response.message;
          //this.setState({otpsendflag: false});  
       }

        
    }).catch(error => {
      this.setState({showloading: false});  
    });


 }

  handleloginSubmit() {
 
    this.clearallerrormesssage(); 
    let cdata = this.state.userlogin;
     let validateform = 1; 
     if(cdata.username.trim()== ""){  
      document.getElementById('loggemail').focus();                   
      document.getElementById('error_email').innerHTML='Please enter the email';
      validateform = 0;
     }     
  
     if(validateform == 1){
       this.setState({showloading: true});    
       this.resetpasswordotp()
     }
  
  }


   componentDidMount(){ 
     this.loadCurrentUser();
      const quvalues = queryString.parse(this.props.location.search);
      if(quvalues.cartid ){     
        let checkcartid = quvalues.cartid; 
        this.setState({cardid: checkcartid});               
      } 
   }



  loadCurrentUser(){
    let locstatus = false;
    let bsource = this.state.bookingsource;    
    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    } 
    
    if (localStorage.getItem(BOOKING_SOURCE) !== null) {
      bsource = localStorage.getItem(BOOKING_SOURCE);     
      this.setState({bookingsource: bsource}); 
      this.getsmartbookingconfigdetails(bsource);      
    }
    else{
      this.setState({bookingsource: bsource}); 
      this.getsmartbookingconfigdetails(bsource);      
    }

  }



  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
            this.setState({loadingthemconfig: true}); 
                    
          }            
      }).catch(error => {
        
      });
  
   }


  resetpasswordotp(){ 
 
    document.getElementById('error_email').innerHTML='';
    let cdata = this.state.userlogin;
    var data ={
      user_email: cdata.username,      
      cartsource: this.state.bookingsource,
     }  
  
     ureqresetpasswordtop(data)
      .then(response => {  
 
          this.setState({showloading: false});  
          if(response.status == "success") {  
            this.setState({otpsendflag: true});              
          }else{
            this.setState({logmessage: ''});    
            document.getElementById('error_email').innerHTML='Invalid Email ID / does not exists';
            this.setState({otpsendflag: false});   
  
         }
  
          
      }).catch(error => {
        this.setState({showloading: false});  
      });

  
   } 


  render() {
    
    let loggedflag = this.state.loggedstatus;
    if (loggedflag == "logged") {
        return <Redirect to='/dashboard' />
    }

    return(

      (this.state.loadingthemconfig == true) 

      ?

        <Layout>
        <Helmet><title>{this.state.smarbookingconfig.sitetitle} - User Portal</title></Helmet>    
          <Header className="login-header" style={{ background: '#222328', padding: 0 }}>
          <div><Row><Col> <div className="login-container "><img class="login_logo" src={this.state.smarbookingconfig.logopath}/></div>  </Col></Row>    
          </div>      
              </Header>
                <Layout>
              <Content style={{ margin: '24px 16px 0' }}> 
              <div className={this.state.smarbookingconfig.themeparentclass}>    
                <Resetpassword 
                    smarbookingconfig ={this.state.smarbookingconfig}
                    showloading={this.state.showloading} 
                    userdata={this.state.userlogin} 
                    fnhandleonchage={this.handleinputonchange} 
                    fnonloginsubmit={this.handleloginSubmit} 
                    otpsendflag = {this.state.otpsendflag}
                    resetpwddata = {this.state.resetpwddata}
                    handlepassworrest = {this.handlepassworrest}
                    fnhandlepassworrest = {this.handleresetpassword}
                    showcontinue = {this.state.showcontinue}
                    handleredirectologinpage = {this.handleredirectologinpage}
                    />  
                    </div>    
              </Content>             
            </Layout>
          </Layout>
          :
          <Spin tip="Loading..." />
    );
  }
}

export default PageUserReset;
