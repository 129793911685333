import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,Select,Table  ,Icon,Spin,Checkbox,Calendar, DatePicker,Popconfirm, Space,Badge,Button,Card,Drawer,List,Row,Col,Collapse,Form, Input, InputNumber ,Upload, Modal } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.classlists;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item actions={[<Link to={{ pathname:'/assignstudents',search:'?id='+title.id}}>Students</Link>]}>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.stdatetext}</strong></span>}
      description={<div>@ {title.locationtext}, Tutor(s): {title.tutortext} <br />Status: {title.classstatus} |  No Participants: {title.totalstudents}</div>}
 />
 
 </List.Item>
  );
  return (
    <List>  
    <List.Item>
  <List.Item.Meta           
      title={<span><strong>1.Susbcribed Annual Plan</strong></span>}
      description={<div>Total Amount: <strong>&pound;250</strong>  | Discount:  <strong>&pound;250</strong>  |  Paid Amount: <strong>&pound;250</strong>  <br /> Payment Source: Stripe | Booking Date: 23-May-2022</div>}
 /> 
 </List.Item> 
 <List.Item>
  <List.Item.Meta           
      title={<span><strong>2.Course - Tango Begineers</strong></span>}
      description={<div>Total Amount: <strong>&pound;250</strong>  | Discount:  <strong>&pound;250</strong>  |  Paid Amount: <strong>&pound;250</strong>  <br /> Payment Source: Stripe | Booking Date: 23-May-2022</div>}
 /> 
 </List.Item>       
    </List> 
  );
}



function UserCategory(props) {

  if(!props){
    return null;
  }

  const category =  ['Leader','Follower'];
  const selecqty = props.selval;
  const listItems = category.map((title) =>
  (selecqty == title) ?     
  <option selected="selected" value={title.toString()}>
     {title}
  </option>
  :     
  <option  value={title.toString()}>
     {title}
  </option>
  );
  return (
  <select id={props.id}  onChange={props.handleinputchangenewuser} >
     <option value="">--Select--</option>
    {listItems}</select>
  );
  }

  function Dropuserlevel(props) {
    if(!props){
      return null;
    }
   
    const gender =  props.levels;
    console.log(gender)
  
    const selecqty = props.selval;
    let listItems = '';
    console.log(gender)
    if(Object.keys(gender).length > 0){

      listItems = Object.keys(gender).map((id) => 
      (selecqty == id.toString()) ?     
  <option selected='selected' value={id.toString()}>
     {gender[id].toString()}
  </option>
  :     
  <option value={id.toString()}>
    {gender[id].toString()}
  </option>  
      
      );
    }
    console.log(listItems)
  
    return (
    <select id={props.id}   onChange={props.handleinputchangenewuser} >
       <option value="">--Select--</option>
      {listItems}</select>
    );
    }
function Countrylists(props) {


  if (!props) {
    return null;
  }

  const classdata = props.countrylist;
  const selecqty = props.selval;
  //id="usrcountry" selval={userdata.country} handleinputchangenewuser={props.handleinputchangenewuser}
  const listItems = classdata.map((title,index) =>
        
          (selecqty == title.country_2_code) ?
          <option selected="selected" value={title.country_2_code}>{title.name}</option>
          :
          <option value={title.country_2_code}>{title.name}</option>
       
  );
  return (
    <select id={props.id} onChange={props.handleinputchangenewuser}>
      {listItems}
    </select>
  );
}

function Dropsalutions(props) {


  if (!props) {
    return null;
  }


  const gender =  ['Mr.','Mrs.','MissMs.','Dr.','Prof.','Rev.','Other'];

  const selecqty = props.selval;
  const listItems = gender.map((title) =>
  (selecqty == title) ?     
  <option selected='selected' value={title.toString()}>
     {title}
  </option>
  :     
  <option value={title.toString()}>
     {title}
  </option>
  );
  return (
  <select id={props.id}  onChange={props.handleinputchangenewuser} >
     <option value="">--Select--</option>
    {listItems}</select>
  );
  }

  function Dateofbirth(props) {


    if (!props) {
      return null;
    }
  
  
    const gender =  ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  
    const selecqty = props.selval;
    const listItems = gender.map((title) =>
    (selecqty == title) ?     
    <option selected='selected' value={title.toString()}>
       {title}
    </option>
    :     
    <option value={title.toString()}>
       {title}
    </option>
    );
    return (
    <select id={props.id}  onChange={props.handleinputchangenewuser} >
       <option value="">--Select--</option>
      {listItems}</select>
    );
    }
function Newuserregister(props){

  if (!props) {
    return null;
  }

  let userdata = props.userdetails;
  return (
    <div>
<div className="profile_info_left mt-10">

<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Salutation</span>  
               </div>
               <div className="manage_course_info">
               <Dropsalutions id="salutation"  selval = {userdata.salutation}  handleinputchangenewuser ={props.handleinputchangenewuser}/>                
               <span className="erromessage" id="error_salutation"></span>
               </div>   
           </div>
           </div>
           </div>


<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>First Name</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="firstname" name="firstname" value={userdata.firstname}  autoComplete="off"  onChange={props.handleinputchangenewuser}  />
               <span className="erromessage" id="error_firstname"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Last Name</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="lastname" name="lastname" value={userdata.lastname}   autoComplete="off"  onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_lastname"></span>
               </div>   
           </div>
           </div>
           </div>


<div><b>Date of birth</b> (please fill in to get special offers to celebrate your birthday)</div>
           <div className="row dob_class">

           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Day</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="dob_day" name="dob_day" value={userdata.dob_day}   autoComplete="off"  onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_dob_day"></span>
               </div>   
           </div>
           </div>
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Month</span>  
               </div>
               <div className="manage_course_info">
               <Dateofbirth id="dob_month"  selval = {userdata.dob_month}  handleinputchangenewuser ={props.handleinputchangenewuser}/>                
               <span className="erromessage" id="error_dob_month"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Email</span>  
               </div>
               <div className="manage_course_info">           
               {userdata.email}
               <span className="erromessage" id="error_usremail"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Phone Number</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrphoneno" name="usrphoneno"  value={userdata.phone_number} autoComplete="off" onChange={props.handleinputchangenewuser}  />
               <span className="erromessage" id="error_usrphoneno"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Billing Address 1</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrbiladdress1" name="usrbiladdress1" value={userdata.address_line1} autoComplete="off" onChange={props.handleinputchangenewuser}  />
               <span className="erromessage" id="error_usrbiladdress1"></span>
               </div>   
           </div>
           </div>
           </div>

          
   
</div>


<div className="profile_info_right mt-10">


<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Billing Address 2</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrbiladdress2" name="usrbiladdress2"  value={userdata.address_line2} autoComplete="off" onChange={props.handleinputchangenewuser}  />
               <span className="erromessage" id="error_usrbiladdress2"></span>
               </div>   
           </div>
           </div>
           </div>
       
           
           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>City</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrcity" name="usrcity"  value={userdata.city} autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrcity"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Postcode</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrpostcode" name="usrpostcode" value={userdata.post_code}  autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrpostcode"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Country</span>  
               </div>
               <div className="manage_course_info">
               <Countrylists countrylist={props.countrylist} id="usrcountry" selval={userdata.country} handleinputchangenewuser={props.handleinputchangenewuser}/>               
               <span className="erromessage" id="error_usrcountry"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Entry Level</span>  
               </div>
               <div className="manage_course_info">
               <UserCategory id="user_category" selval={userdata.user_category} handleinputchangenewuser={props.handleinputchangenewuser}/>               
               <span className="erromessage" id="error_user_category"></span>
               </div>   
           </div>
           </div>
           </div>
          
           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Subscribe Newsletter</span>  
               </div>
               <div className="manage_course_info">           
               <Checkbox onChange={props.handlenewsleteonchange} checked={userdata.newsletter_subscription} />
               <span className="erromessage" id="error_subnewsletter"></span>
               </div>   
           </div>
           </div>
           </div>



</div>
<div className="width-full mt-10">
<div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style width-auto" onClick={props.handlsubmitnewuser}>Save</button>        
          </div>
       

          </div>
          </div>

   </div>
   </div>

      );


}



function Manageaccount(props){

  if (!props) {
    return null;
  }

  return (
    <div>
<div className="profile_info_left mt-10">
<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Password</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "password" id="newpassword" name="newpassword"  autoComplete="off" onChange={props.handlepassordinpurtonchange}  />
               <span className="erromessage" id="error_newpassword"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Confirm Password</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "password" id="nconfirmpassword" name="nconfirmpassword"  autoComplete="off" onChange={props.handlepassordinpurtonchange}  />
               <span className="erromessage" id="error_nconfirmpassword"></span>
               </div>   
           </div>
           </div>
           </div>


           <div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style width-auto" onClick={props.hanlesubmitresetpassword}>Reset</button>        
          </div>
       

          </div>
          </div>
           
   
</div>


<div className="profile_info_right mt-10">

</div>



   </div>
      );


}

function Managelevels(props){

  if (!props) {
    return null;
  }
  let userdata = props.userdetails;
  return (
<div>
<div className="profile_info_left mt-10">
<div className="row">
   <div className="filer_course_2">
   <div className="top_header_filer">
       <div className="manage_course_label level_label">
       <span>To change your level, please send a request to the teachers by choosing your level.</span>  
       </div>
       <div className="manage_course_info ">
         <Dropuserlevel id = "user_level" selval = {userdata.user_level} handleinputchangenewuser ={props.handleinputchangenewuser} levels={props.levels}/>
        <span className="erromessage" id="error_user_level"></span>
       </div>   
   </div>
   </div>
   </div>

           <div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style width-auto" onClick={props.handlelevelrequest}>Submit</button>        
          </div>
       

          </div>
          </div>
           
   
</div>

<div className="profile_info_right mt-10">
</div>
</div>
);
}

function Courseclassesnew(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Your Details" key="1">
    <Newuserregister 
      classlists={props.classlists} 
      userdetails ={props.userdetails} 
      handleinputchangenewuser = {props.handleinputchangenewuser}
      handlsubmitnewuser = {props.handlsubmitnewuser}
      countrylist = {props.countrylist}
      handlenewsleteonchange = {props.handlenewsleteonchange}
      levels = {props.levels}
    />
    </Panel></Collapse><br />
    <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Your Level" key="1">
    <Managelevels 
        userdetails ={props.userdetails} 
        levels={props.levels} 
        handleinputchangenewuser={props.handleinputchangenewuser} 
        handlelevelrequest = {props.handlelevelrequest}    
    />
    </Panel></Collapse><br />
    <Collapse expandIconPosition="right" defaultActiveKey={['1']}>
    <Panel header="Reset Password" key="1">
    <Manageaccount 
        classlists={props.classlists} 
        handlepassordinpurtonchange={props.handlepassordinpurtonchange} 
        hanlesubmitresetpassword = {props.hanlesubmitresetpassword}    
    />
    </Panel></Collapse><br />
     </div>
  );
}


class CompMyprofile extends  Component{

    constructor(props){
         super(props); 
    }



render(){

  
    return(              
      <div className="dashboard-common">
      <div className="box-model-manage">
      <div className="site-card-wrapper">
      <Spin tip="Loading..."  spinning={this.props.loadclassflag}> 
      <Courseclassesnew                
                classlists = {this.props.classlists} 
                userdetails = {this.props.userdetails} 
                handleinputchangenewuser = {this.props.handleinputchangenewuser}  
                handlsubmitnewuser = {this.props.handlsubmitnewuser}  
                countrylist = {this.props.countrylist} 
                handlenewsleteonchange = {this.props.handlenewsleteonchange}  
                handlepassordinpurtonchange = {this.props.handlepassordinpurtonchange}   
                hanlesubmitresetpassword = {this.props.hanlesubmitresetpassword} 
                handlelevelrequest = {this.props.handlelevelrequest}
                levels = {this.props.levels} 
           />
           </Spin>

      </div></div></div>
     
     
     
    );
}
}
export default CompMyprofile;