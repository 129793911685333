import React, { Component } from "react";
import { Popover, Button } from 'antd';
import './CommonHeader.css';
import { Layout, Menu, Icon,Breadcrumb,Avatar, Row, Col,Dropdown,Badge } from 'antd';

import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-KMJKHPKJ'
  }
TagManager.initialize(tagManagerArgs)
  
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;


  function Dropheader(props){

    const dropmenu = (
      <Menu className="profile-menu">
        <Menu.Item key="0">
        <Link to={{ pathname: "profile" ,email :props.userdata.firstname }}>My Profile</Link>
         
        </Menu.Item>
      
        <Menu.Item key="1">
        <button onClick={props.fnlogout} >Logout</button>
        </Menu.Item>
       
      </Menu>
    );
   
    return(
    <Dropdown overlay={dropmenu} trigger={['click']}>
  
    <a className="ant-dropdown-link" href="#">
    <Avatar style={{ backgroundColor: '#87d068', marginRight: 10 }} icon="user" /><span className="user_details"> Logged as : {props.userdata.firstname} </span> <Icon type="down" />
    </a>
  </Dropdown>
    );
  }

class CommonHeader extends Component {
 
     constructor(props){
         super(props);
       
     }
   
   
    render() {
        
        return (
          
           
            <Header style={{ background: '#fff', padding: 0 }}>

{

(this.props.loggedstatus == "logged")  ?
<div className={this.props.smarbookingconfig.themeparentclass}>
    <Row>
      <Col span={12} className="leftsettings"> <div className="logosmart"><a href={this.props.smarbookingconfig.homeurl}><img src={this.props.smarbookingconfig.logopath} /> </a></div>  </Col>
      <Col span={12} className="rightsettings">  
      <Link to={"/checkoutbasket?cartid=" + this.props.cartid} className="basketicon"><Icon type="shopping-cart" />
      <span>Basket</span></Link> |     
     <Dropheader userdata= {this.props.userdata} fnlogout={this.props.fnlogout}   />      
      </Col>
    </Row>    
  </div> 

:
<div className={this.props.cart_source}>
<Row>
      <Col span={12} className="leftsettings"> <div className="logosmart"><a href={this.props.smarbookingconfig.homeurl}><img src={this.props.smarbookingconfig.logopath}  /></a></div>  </Col>
      <Col span={12} className="rightsettings">   
      <Link to={"/checkoutbasket?cartid=" + this.props.cartid}>Basket</Link> | <Link to="/">My Profile</Link>
      </Col>
    </Row>     
  </div> 

}


     
          </Header>
        );
      }

}
CommonHeader.defaultProps = {
    headertitle: 'Ca header Text',
}

export default CommonHeader;

