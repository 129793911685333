import React, { Component } from "react";
import "../css/fonts.css";
import { Layout, Menu,List,PageHeader, Tag, Button, Statistic, Radio, Row,Col,Collapse,Checkbox,Spin,DatePicker } from 'antd';
import 'antd/dist/antd.css';
import {Elements,PaymentElement,useStripe, useElements,} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {getconfigurlmode} from '../helpers/Apicommon';

//const stripePromise = loadStripe(cartdata.api_key);
//const stripePromise = loadStripe('pk_live_ynk6TtJYd1IVWDoRBM6lXokD00U9TKWowA');

const options = {clientSecret: 'pi_3LC2CXKJd646M6OY00mkmqqm_secret_aXUeuObymPtXN5RM57xQfAgt5'};
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;

function Classlist(props) {


  if (!props) {
    return null;
  }

  const classdata = props.classlists;
  const selecqty = props.selval;
  const listItems = classdata.map((title,index) =>
  <List.Item actions={[<Link to={{ pathname:'/assignstudents',search:'?id='+title.id}}>Students</Link>]}>
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.stdatetext}</strong></span>}
      description={<div>@ {title.locationtext}, Tutor(s): {title.tutortext} <br />Status: {title.classstatus} |  No Participants: {title.totalstudents}</div>}
 />
 
 </List.Item>
  );
  return (
    <List>  
    {listItems}</List> 
  );
}


function Showbillinginformation(props){

    if (!props) {
      return null;
    }

    const srdetails = props.userdetails;
    return (
      <List>  
      <List.Item>
      <List.Item.Meta           
          title={<span><strong>{srdetails.firstname + " " + srdetails.lastname}</strong></span>}
          description={<div>Email: {srdetails.email} <br /> Address : {srdetails.address_line1},{srdetails.city},{srdetails.post_code} </div>}
      /> 
      </List.Item>
      </List> 
    );

}


function Countrylists(props) {


  if (!props) {
    return null;
  }

  const classdata = props.countrylist;
  //console.log(classdata);
  const selecqty = props.selval;
  //id="usrcountry" selval={userdata.country} handleinputchangenewuser={props.handleinputchangenewuser}
  const listItems = classdata.map((title,index) =>
        
          (selecqty == title.country_2_code) ?
          <option selected="selected" value={title.country_2_code}>{title.name}</option>
          :
          <option value={title.country_2_code}>{title.name}</option>
       
  );
  return (
    <select id={props.id} onChange={props.handleinputchangenewuser}>
      {listItems}
    </select>
  );
}


function Dropsalutions(props) {


  if (!props) {
    return null;
  }


  const gender =  ['Mr.','Mrs.','MissMs.','Dr.','Prof.','Rev.','Other'];

  const selecqty = props.selval;
  const listItems = gender.map((title) =>
  (selecqty == title) ?     
  <option selected='selected' value={title.toString()}>
     {title}
  </option>
  :     
  <option value={title.toString()}>
     {title}
  </option>
  );
  return (
  <select id={props.id}  onChange={props.handleinputchangenewuser} >
     <option value="">--Select--</option>
    {listItems}</select>
  );
  }

  function Aboutlist(props){
    
    if(!props){
      return null;
    }
   
    const gender =  props.hearaboutus;
    console.log(gender)
  
    const selecqty = props.selval;
    let listItems = '';
    console.log(gender)
    if(Object.keys(gender).length > 0){

      listItems = Object.keys(gender).map((id) =>   
      <option value={id.toString()}>
         {gender[id].toString()}
      </option>
      );
    }
    console.log(listItems)
    // else{
    //   return  <select></select>;
    // }
    // const listItems = gender.map((title) =>
    // (selecqty == title.id) ?     
    // <option selected='selected' value={title.id.toString()}>
    //    {title.template_title.toString()}
    // </option>
    // :     
    // <option value={title.id.toString()}>
    //    {title.template_title.toString()}
    // </option>
    // );
    return (
    <select id={props.id} dataname={props.dataname}  onChange={props.handleinputchangenewuser} >
       <option value="">--Select--</option>
      {listItems}</select>
    );
  }

  function UserCategory(props) {

    if(!props){
      return null;
    }
  
    const category =  ['Leader','Follower'];
    const selecqty = props.selval;
    const listItems = category.map((title) =>
    (selecqty == title) ?     
    <option selected="selected" value={title.toString()}>
       {title}
    </option>
    :     
    <option  value={title.toString()}>
       {title}
    </option>
    );
    return (
    <select id={props.id}  onChange={props.handleinputchangenewuser} >
       <option value="">--Select--</option>
      {listItems}</select>
    );
    }


    function UserCategoryattendee(props) {

      if(!props){
        return null;
      }
    
      const category =  ['Leader','Follower'];
      const selecqty = props.selval;
      const listItems = category.map((title) =>
      (selecqty == title) ?     
      <option selected="selected" value={title.toString()}>
         {title}
      </option>
      :     
      <option  value={title.toString()}>
         {title}
      </option>
      );
      return (
      <select id={props.id}  onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, props.index)}} >
         <option value="">--Select--</option>
        {listItems}</select>
      );
      }

      function HearAboutus(props) {

        if(!props){
          return null;
        }
      
        const category =  ['Leader','Follower'];
        const selecqty = props.selval;
        const listItems = category.map((title) =>
        (selecqty == title) ?     
        <option selected="selected" value={title.toString()}>
           {title}
        </option>
        :     
        <option  value={title.toString()}>
           {title}
        </option>
        );
        return (
        <select id={props.id}  onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, props.index)}} >
           <option value="">--Select--</option>
          {listItems}</select>
        );
        }


function Loadaddtionalattendeeform(props){


  if (!props) {
    return null;
  }

  return (
   <div>
   <div className="profile_info_left mt-10">
    

    </div>
    </div>

  );

}


function Newuserregister(props){

  if (!props) {
    return null;
  }

  return (
    <div>
<div className="profile_info_left mt-10">

<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Salutation</span>  
               </div>
               <div className="manage_course_info">           
               <Dropsalutions id="salutation" selval={props.userdetails.salutation}   handleinputchangenewuser ={props.handleinputchangenewuser}/>                
               <span className="erromessage" id="error_salutation"></span>
               </div>   
           </div>
           </div>
           </div>

<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>First Name</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="firstname" name="firstname" value={props.userdetails.firstname}  autoComplete="off" onChange={props.handleinputchangenewuser}   />
               <span className="erromessage" id="error_firstname"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Last Name</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="lastname" name="lastname"  value={props.userdetails.lastname} autoComplete="off" onChange={props.handleinputchangenewuser}  />
               <span className="erromessage" id="error_lastname"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Email</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usremail" name="usremail"  value={props.userdetails.email}  autoComplete="off" onBlur={props.handlvalidateemailexists}  onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usremail"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Phone Number</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrphoneno" name="usrphoneno" value={props.userdetails.phone_number}   autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrphoneno"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Password</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "password" id="usrpassword" name="usrpassword"   autoComplete="off"  onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrpassword"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Confirm Password</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "password" id="usrcnfpassword" name="usrcnfpassword"  autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrcnfpassword"></span>
               </div>   
           </div>
           </div>
           </div>
</div>


<div className="profile_info_right mt-10">

<div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Billing Address 1</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrbiladdress1" name="usrbiladdress1"  value={props.userdetails.address_line1} autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrbiladdress1"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Billing Address 2</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrbiladdress2" name="usrbiladdress2" value={props.userdetails.address_line2}  autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrbiladdress2"></span>
               </div>   
           </div>
           </div>
           </div>
           
           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>City</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrcity" name="usrcity" value={props.userdetails.city}   autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrcity"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Postcode</span>  
               </div>
               <div className="manage_course_info">
           
               <input type= "text" id="usrpostcode" name="usrpostcode" value={props.userdetails.post_code}  autoComplete="off" onChange={props.handleinputchangenewuser} />
               <span className="erromessage" id="error_usrpostcode"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Country</span>  
               </div>
               <div className="manage_course_info">
               <Countrylists countrylist={props.countrylist} selval={props.userdetails.country} id="usrcountry" handleinputchangenewuser={props.handleinputchangenewuser}/>               
               <span className="erromessage" id="error_usrcountry"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Entry Level</span>  
               </div>
               <div className="manage_course_info">
               <UserCategory id="user_category" selval={props.userdetails.user_category} handleinputchangenewuser={props.handleinputchangenewuser}/>               
               <span className="erromessage" id="error_user_category"></span>
               </div>   
           </div>
           </div>
           </div>

           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>How did you hear about us? </span>  
               </div>
               <div className="manage_course_info">
               <Aboutlist hearaboutus={props.hearaboutus} id="about_us" selval={props.userdetails.about_us} handleinputchangenewuser={props.handleinputchangenewuser}/>               
               <span className="erromessage" id="error_about_us"></span>
               </div>   
           </div>
           </div>
           </div>
          
           { 
        (props.userdetails.about_us == 8) ?
        <div className="row">
        <div className="filer_course_2">
        <div className="top_header_filer">
            <div className="manage_course_label">
            <span>Please Specify </span>  
            </div>
            <div className="manage_course_info">
        
            <input type= "text" id="about_us_others" name="about_us_others" value={props.userdetails.about_us_others}  autoComplete="off" onChange={props.handleinputchangenewuser} />
            <span className="erromessage" id="error_about_us_others"></span>
            </div>   
        </div>
        </div>
        </div>
        : 
        <span></span>
        }
           
          
           <div className="row">
           <div className="filer_course_2">
           <div className="top_header_filer">
               <div className="manage_course_label">
               <span>Subscribe Newsletter</span>  
               </div>
               <div className="manage_course_info">           
               <Checkbox id="usrsubscribe" name="usrsubscribe" onChange={props.handlenewsleteonchange} />             
               </div>   
           </div>
           </div>
           </div>

           <div className="row terms_row">
           <div className="filer_course_2">
           <div className="top_header_filer">
              
               <div className="manage_course_info terms_info">           
               <Checkbox id="terms" name="terms" onChange={props.handletermsconditionsonchange} />             
               </div>  
               <div className="manage_course_label terms_label">
               <span>{props.terms_conditions} I agree to the <a target="_blank" href={ (props.bookingsource == 'smtf') ? 'https://www.tango-fever.com/terms-and-conditions/' : 'https://www.tango-amistoso.co.uk/terms-and-conditions/'}>terms and conditions</a></span>  
               </div> 
           </div>
           </div>
           </div>

</div>
{

(props.attendeeflag == 1) ?

''
:
<div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style" onClick={props.handlsubmitnewuser} disabled={props.terms_conditions === true ? false:true}>Proceed to Payment</button>        
          </div>
          </div>
          </div>
}


   </div>
      );


}



function Userlogin(props){
  if (!props) {
    return null;
  }

  return (
     <div>
<div class="row">
          <div class="row-label">
          <label>Username</label>
          </div>
          <div class="row-label">
          <input type="text" class="input-border" name="email" id="loggemail" className="login-textbox" value={props.userdata.username} onChange={props.handleinputonchange}   />
          <div class="erromessage" id="error_email"></div>
          </div>
          </div>

          <div class="row">
          <div class="row-label">
          <label>Password</label>
          </div>
          <div class="row-label">
          <input type="password" class="input-border" name="passowrd" id="loggpassword" className="login-textbox" value={props.userdata.password} onChange={props.handleinputonchange} />
          <div class="erromessage" id="error_password" ></div>
          </div>
          </div>

          <div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style" onClick={props.handleloginSubmit}>Login</button> 
       <div className="checkoutforgotpass ca-cartlgn-desktop">
        <a className="button" href={"/resetpassword?cartid="+props.cartdata.cartid}>Forgotten Password?</a>
        </div>
       <div class="erromessage" id="error_login" ></div>       
          </div>
       

          </div>
          </div>


     </div>
  );


}

function Userlogin_old(props){
  if (!props) {
    return null;
  }
  const cardid = props.cartdata.cartid; 

  const urlrediect = 'https://www.tango-amistoso.co.uk/my-account?redirect_to=' +  encodeURIComponent('https://www.tango-amistoso.co.uk/user/checkoutbasket?cartid='+ cardid + '&wplogged=true');
  return (
     <div>

          <div class="row">
          <div class="login-mt">        
          <div class="login-btn">       
       <a href={urlrediect}><button class="login-btn-style">Login</button> </a>
       <div class="erromessage" id="error_login" ></div>       
          </div>
       

          </div>
          </div>


     </div>
  );


}


function CheckoutFormnew(props){

  const stripe = useStripe();
  const elements = useElements();
  
  if (!props) {
    return null;
  }

  let cinpage = getconfigurlmode();
  const cartdata = props.cartdata;
  const redirectpaymenturl = cinpage.paymentconfirmurl;

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    document.getElementById('error_checkout').innerHTML = 'Your Payment is being processed. Please wait....';
    
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirectpaymenturl + cartdata.cartid,
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      //console.log(result.error.message);
      document.getElementById('error_checkout').innerHTML = result.error.message;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button>Submit</button>
      <div class="erromessage" id="error_checkout" ></div>
    </form>
  );

}


const CheckoutForm = () => {

  const stripe = useStripe();
  const elements = useElements();
  const loadpayment = true;

  const handleSubmit = async (event) => {
    
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    loadpayment = true;
    
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://dev.samrantech.com/user/bookingconfirm?cartid=",
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      loadpayment = false;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      loadpayment = false;
    }
  };



  return (
    <Spin tip="Loading..."  spinning={loadpayment} >
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button>Submit</button>
    </form>
    </Spin>
  );
};


function Loadstdate(props){
  const dateFormat = 'YYYY-MM-DD';
  if (!props) {
    return null;
  }

  let itemdata = props.substdate
  let itemindex = props.itenindex;
  return (
    <DatePicker defaultValue={moment(itemdata.substdate, dateFormat)} onChange={(date, dateString)=>{props.handleonchangesubstdate(date, dateString,itemindex)}} />
  );
  


}



function ItemQtyDropdown(props) {

  if (!props) {
    return null;
  }

  const numbers = [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]; 
  const selecqty = props.selval;
  const itemdata = props.itemdata

  const listItems = numbers.map((number) =>
  (selecqty == number) ?     
  <option selected='selected' value={number.toString()}>
      {number}
    </option> :     
  <option value={number.toString()}>
      {number}
    </option>
  );
  return (
    <select id={props.itemid} onChange={(e)=>{props.handlonchangeitemqty(e,itemdata)}} >{listItems}</select>
  );
}

function Attendeeform(props){

  if (!props) {
    return null;
  }
  const attedeedata = props.itemdata.attendeelist; 
  const listItems = attedeedata.map((title,index) =>
 <tr>
  <td>{index+1}</td>
  <td><input type="text" id="afname" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}}  /></td>
  <td><input type="text" id="alname" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}} /></td>
  <td><input type="text" id="aemail" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}} /></td>
  <td><UserCategory id="ausercategory" handleinputchangenewuser={props.handleonchangeattendee}/>  </td>
  </tr>
 );


 const listItems2 = attedeedata.map((title,index) =>
 <div>
<h3 className="attendance-details">Enter the Attendee {index+1} Details</h3> 
<div class="profile_info_left mt-10">
<div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>First Name</span></div>
               <div class="manage_course_info"><input type="text" id="afirstname" name="afirstname" autocomplete="off" value={title.firstname} onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}} /></div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>Last Name</span></div>
               <div class="manage_course_info"><input type="text" id="alastname" name="alastname" autocomplete="off" value={title.lastname} onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}}/></div>
            </div>
         </div>
      </div>
  </div>

  <div class="profile_info_right mt-10">



<div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>Email</span></div>
               <div class="manage_course_info"><input type="text" id="aemail" name="aemail" value={title.email} autocomplete="off" onChange={(e)=>{props.handleonchangeattendee(e,props.cindex, index)}}/></div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="filer_course_2">
            <div class="top_header_filer">
               <div class="manage_course_label"><span>Entry Level</span></div>
               <UserCategoryattendee cindex={props.cindex} index={index} selval={title.usercategory} id="ausercategory" handleonchangeattendee={props.handleonchangeattendee}/> 
            </div>
         </div>
      </div>
     

  </div>

  </div>
 );
  
  return (
    <div>{ listItems2 }    
    <span className="erromessage" id={"error_attendee_" + props.cindex }>&nbsp;</span>
    </div> 
  );



}


function Loadaddtionalattendees(props){

  if (!props) {
    return null;
  }
  var strdisp = '';
  const classdata = props.cartdata.cartitems; 
  const listItems = classdata.map((title,index) =>
   <div>
    <h2>Addtional Attendee for {title.item_name}</h2>    
    <Attendeeform 
      itemdata  = {title}
      cindex = {index}
      handleonchangeattendee = {props.handleonchangeattendee}
      />
   
   </div>

  );
  return (
    <div> 
    {listItems}
   
    <div class="row">
          <div class="login-mt">        
          <div class="login-btn">
       <button class="login-btn-style" onClick={props.handlsubmitnewuser}>Proceed to Payment</button>        
          </div>
       

          </div>
          </div>
    
    </div> 
  );

}

function Loadcartitems(props) {


  if (!props) {
    return null;
  }

  const classdata = props.cartdata.cartitems;
  //const selecqty = props.selval handlonchangeitemqty;
  const listItems = classdata.map((title,index) =>
  <List.Item actions={[<Button onClick={(e)=>{props.handleremoveitems(title)}}>X</Button>]}>

  {(title.item_category == "subscription")  ?
  <List.Item.Meta           
      title={<span><strong>{index+1}.{title.item_name}</strong></span>}
      description={<div>Item Qty: <strong>{title.item_qty}</strong>, Price: <strong>&pound;{title.item_price}</strong> <br /> <i>Your Subscription will start from  {title.substdate}. If you wish to change the starting date please select the date from tbe below </i><br /> Subscription Start Date: <Loadstdate substdate={title} itenindex = {index} handleonchangesubstdate={props.handleonchangesubstdate} /></div>}
 /> 
 :
 <List.Item.Meta           
      title={<span><strong>{index+1}.{title.item_name}</strong></span>}
      description={<div>Item Qty: <ItemQtyDropdown itemid="iqty" selval={title.item_qty} itemdata ={title}  handlonchangeitemqty={props.handlonchangeitemqty}  />, Price: &pound;{title.item_price} </div>}
 /> 

 }

 </List.Item>   



  );
  return (
    <List> 
    {listItems}</List> 
  );
}



function Showyourbasket(props) {

  if (!props) {
    return null;
  }

  return (
   <div>
     <Collapse defaultActiveKey={['1','2','3','4']} expandIconPosition="right" >
    <Panel header="your basket" key="1">
    <Loadcartitems 
        cartdata = {props.cartdata}
        handleremoveitems ={props.handleremoveitems}
        handleonchangesubstdate = {props.handleonchangesubstdate}
        handlonchangeitemqty = {props.handlonchangeitemqty}
    />
    </Panel>
    <Panel header="Sign in  / Register" key="2">

        {
            (props.showpaymentform == true) ?
               
              ''
             :

            <Radio.Group value={props.userflag} onChange={props.handleonchangeuserradio}>
            <Radio value={1}>New User</Radio>  
            <Radio value={2}>Existing User</Radio>            
            </Radio.Group>
          
        }    

      <div>
        {         
           (props.userflag == 2) ?            
           <Userlogin 
              handleinputonchange = {props.handleinputonchange}
              handleloginSubmit = {props.handleloginSubmit}
              userdata={props.userdata}
              cartdata = {props.cartdata}
           />           
           :           
           (props.showpaymentform == true) ?           
           <Showbillinginformation userdetails ={props.userdetails} />
           :
           <Newuserregister 
           handlepaymentredirect={props.handlepaymentredirect} 
           handleinputchangenewuser = {props.handleinputchangenewuser}
           handlsubmitnewuser = {props.handlsubmitnewuser}
           handlvalidateemailexists = {props.handlvalidateemailexists}
           countrylist = {props.countrylist}
           hearaboutus = { props.hearaboutus}
           handlenewsleteonchange = {props.handlenewsleteonchange}
           attendeeflag = {props.cartdata.attendeeflag}
           userdetails ={props.userdetails}
           handletermsconditionsonchange = {props.handletermsconditionsonchange}
           terms_conditions = {props.terms_conditions}
           bookingsource = {props.bookingsource}
        />
           
        }      
    
      </div>
      </Panel> 

      {

        (props.showpaymentform == true)  ?           
          ''
        :
          (props.cartdata.attendeeflag == 1 && props.userflag != 2)  ?

              <Panel header="Addtional Attendee(s)" key="3">
              <Loadaddtionalattendees 
                cartdata = {props.cartdata}
                handleremoveitems ={props.handleremoveitems}
                handleonchangesubstdate = {props.handleonchangesubstdate}
                handlonchangeitemqty = {props.handlonchangeitemqty}
                handleonchangeattendee = {props.handleonchangeattendee}                
                handlsubmitnewuser = {props.handlsubmitnewuser}
              />
              </Panel>
          :
            ''
      }

      

      {         
           (props.showpaymentform == true) ?            
           <Panel header="Payment" key="4">
           <Elements stripe={props.stripepromise} options={props.stripepaymentintent}>
           <CheckoutFormnew  cartdata={props.cartdata}/>
           </Elements>
           </Panel>  
           
           :
           
           ''
      
        }  


  
  </Collapse>
     </div>
  );
}




class CompCheckoutbasket extends  Component{

    constructor(props){
         super(props); 
    }
    componentWillReceiveProps(props) {

      if (props.cartdata.api_key) {
         
          loadStripe(props.cartdata.api_key).then(res=>{
            this.stripePromise = res
          })
      }    
     
  }




render(){

  
    return(              
      <div className="dashboard-common-checkout">
      <div className="box-model-manage-checkout">
      <div className="site-card-wrapper-checkout">
      <div className="checkoutbasket">

      <PageHeader title="Order Summary">
      <Row type="flex">        
        <Statistic
          title="Total Amount"
          prefix="&pound;"
          value={this.props.cartdata.totalamount}
          style={{
            margin: '0 32px',
          }}
        />
        <Statistic
          title="Discount"
          prefix="&pound;"
          value={this.props.cartdata.discountamount}
          style={{
            margin: '0 32px',
          }}
        />
        <Statistic title="Grand Total" prefix="&pound;" value={this.props.cartdata.grand_total} />
      </Row>
    </PageHeader>    
   

     {
        (this.props.cartdata.cartcount == 0) 
                
        ?
          <div>No Items in the basket</div>

        :

        <Showyourbasket 
              userflag = {this.props.userflag}
              handleonchangeuserradio = {this.props.handleonchangeuserradio}
              handlepaymentredirect = {this.props.handlepaymentredirect}
              cartdata = {this.props.cartdata}
              handleinputonchange = {this.props.handleinputonchange}
              handleloginSubmit = {this.props.handleloginSubmit}
              userdata={this.props.userdata}
              showpaymentform = {this.props.showpaymentform}
              handleinputchangenewuser = {this.props.handleinputchangenewuser}
              handlsubmitnewuser ={this.props.handlsubmitnewuser}
              stripepaymentintent = { this.props.stripepaymentintent }
              userdetails = {this.props.userdetails}
              payactivetabs = {this.props.payactivetabs}
              handlvalidateemailexists = {this.props.handlvalidateemailexists}
              handleremoveitems = {this.props.handleremoveitems}
              countrylist = {this.props.countrylist}
              hearaboutus = {this.props.hearaboutus}
              handlenewsleteonchange = {this.props.handlenewsleteonchange}
              handleonchangesubstdate = {this.props.handleonchangesubstdate}
              handlonchangeitemqty = {this.props.handlonchangeitemqty}
              handleonchangeattendee = {this.props.handleonchangeattendee}
              validateattendeedata = {this.props.validateattendeedata}
              stripepromise = {this.stripePromise}
              handletermsconditionsonchange = {this.props.handletermsconditionsonchange}
              terms_conditions = {this.props.terms_conditions} 
              bookingsource = {this.props.bookingsource} 
            />
     }

     
</div>
      </div></div></div>
     
     
     
    );
}
}
export default CompCheckoutbasket;