import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './Checkoutconfirm.css';
import "../../css/fonts.css";
import { Layout, Menu,Collapse,List,PageHeader, Tag, Button, Statistic, Radio, Spin } from 'antd';
import CommonHeader from '../../common/CommonHeader';
import CommonFooter from '../../common/CommonFooter';
import Leftmenu from '../../components/Leftmenu';
import PageBreadCrumb from '../../common/Pagebreadcrumb';
import CompCheckoutconfirm from '../../components/Checkoutconfirm';
import CompCheckoutconfirmlogged from '../../components/Checkoutconfirmlogged';
import { getcartdetails,getbookingconfirmation,getsmartconfiginfo } from '../../helpers/Apicommon';
import { Link } from 'react-router-dom';
import { sam_adminloggedstatus,CA_LOOGED_EMAIL ,CA_LOOGED_USER_DATA,CA_ACCESS_TOKEN,TA_CART_ID,BOOKING_SOURCE} from '../../constants';
import { Helmet } from 'react-helmet';

const queryString = require('query-string');

const databreadcrumb = [
  {
    title: 'Booking Confirmation',
    linkurl: ''
  },
  
];

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;


class Checkoutconfirm extends Component {

  constructor(props){
    super(props);
    this.state = {
      loggedstatus:'logged',
      userlogin:{
          username: '',
          password: '',
      },
      loggeuserdata:{
        firstname: '',
        lastname:'',
        email:''
      }, 
      userflag:1,
      cartdata:{
        cartid: '',
        cartitems: [],
        userdetails: [],
        discountdata:[], 
        totalamount: 0,
        discountamount:0,
        grandtotalamount:0,
        cartcount: 0,       
      },
      useremail: '',
      loadsecflag: 0,
      clientsecretintent: '',
      loadclassflag: false,
      cart_source: '',
      smartbooking:[],
      smarbookingconfig: {
        logopath:'',
        themeparentclass:'',
        sitetitle:'',
        short_desc:'',
        homeurl:'',
      },
      loadingthemconfig: false,
      bookingsource: 'smta', 
      showCredits: false,  
  }   
        this.handleLogout = this.handleLogout.bind(this); 
  }



  handleLogout() {    
    localStorage.removeItem(CA_ACCESS_TOKEN);
    localStorage.removeItem(CA_LOOGED_EMAIL);   
    localStorage.removeItem(sam_adminloggedstatus);  
    localStorage.removeItem(CA_LOOGED_USER_DATA); 
    localStorage.removeItem(TA_CART_ID);     
    this.props.history.push('/');     
}
 

   componentDidMount(){ 
    
    this.loadCurrentUser();

    const quvalues = queryString.parse(this.props.location.search);
    
    if(quvalues.cartid  &&  quvalues.payment_intent || quvalues.pay_credits){     
          let checkcartid = quvalues.cartid;  
          let paymentintent = quvalues.payment_intent;  
          let paymentcredit = quvalues.pay_credits;  
          this.bookingconfirmationget(checkcartid,paymentintent,paymentcredit);        
    }
     
    
   }

   bookingconfirmationget(cartid,paymentintent,paymentcredit){

        this.setState({loadclassflag: true}); 
        const data={
          cart_id: cartid, 
          paymentintentid: paymentintent,
          paymentcredit: paymentcredit
        }     
        getbookingconfirmation(data).then(response => {
              if(response.status == 'success'){            
                let rtcartdata = this.state.cartdata;
                rtcartdata.cartid = response.cart_id;
                rtcartdata.cartitems = response.cart_items;
                rtcartdata.totalamount = response.total_price;
                rtcartdata.discountamount = response.discount_value;
                rtcartdata.grand_total = response.grand_total;
                rtcartdata.cartcount =response.cart_count;


                let cartsource  = response.cart_source;
                if(cartsource == "smtf"){
                  document.documentElement.style.setProperty('--primarycolor', "#890009");                  
                }else
                {
                  document.documentElement.style.setProperty('--primarycolor', "#c33131");                              
                }
                this.setState({cart_source: response.cart_source});
                this.setState({useremail:response.user_data[0].email});
                this.setState({cartdata: rtcartdata});
                this.setState({smartbooking:  response.smart_config});
                this.setState({loadclassflag: false});
                this.setState({credits_pay: response.credits_pay});
                
                window && window.dataLayer && window.dataLayer.push({
                  'event':'ec_purchase',
                  'order_value': this.state.cartdata.grand_total,
                  'order_id': this.state.cartdata.cartid,
                  'enhanced_conversion_data': {
                    "email": this.state.useremail
                  }
                });
              }
             
          });


   }
   


   loadCurrentUser(){

    let locstatus = false;
    let bsource = this.state.bookingsource; 
    
    if (localStorage.getItem(sam_adminloggedstatus) !== null) {
      locstatus = localStorage.getItem(sam_adminloggedstatus);
      this.setState({loggedstatus: locstatus});       
    }else{
      this.setState({loggedstatus: locstatus}); 
    }
    

    if (localStorage.getItem(CA_LOOGED_USER_DATA) !== null) {
      let lousrlogdata = JSON.parse(localStorage.getItem(CA_LOOGED_USER_DATA));    
      let udata  = this.state.loggeuserdata;
      udata.firstname = lousrlogdata.name;
      udata.lastname = "";
      udata.email = lousrlogdata.email;
      udata.userid = lousrlogdata.usrid;
      this.setState({loggeuserdata: udata}); 
    }

    if (localStorage.getItem(BOOKING_SOURCE) !== null) {
      bsource = localStorage.getItem(BOOKING_SOURCE);      
      this.setState({bookingsource: bsource}); 
      this.getsmartbookingconfigdetails(bsource);      
    }  
    

  }

  getsmartbookingconfigdetails(bsource){ 
 
    var data ={ bsource: bsource }  
  
    getsmartconfiginfo(data)
      .then(response => {             
          if(response.status == "success") { 
            let confidatga = response.configdetails;
            let sconfigdata  = this.state.smarbookingconfig;
            sconfigdata.logopath = confidatga.config_paramas.logopath;
            sconfigdata.themeparentclass = confidatga.config_paramas.themeparentclass;
            sconfigdata.sitetitle = confidatga.config_paramas.sitetitle;
            sconfigdata.short_desc = confidatga.config_paramas.short_desc;
            sconfigdata.homeurl = confidatga.config_paramas.homeurl;
            document.documentElement.style.setProperty('--primarycolor', confidatga.config_paramas.primarycolor);   
            this.setState({smarbookingconfig: sconfigdata}); 
            this.setState({loadingthemconfig: true});                        
          }            
      }).catch(error => {
        
      });
  
   }


  render() {
    
    let loggedflag = this.state.loggedstatus;
    if (loggedflag == "logged") {
       // return <Redirect to='/dashboard' />
    }

    const text = 'adsfasdfasdf';
    return(


<div>
<Spin tip="Loading..."  spinning={this.state.loadclassflag} >

{
     (loggedflag == "logged")  ?

     <div>
   
   <Layout>   
   <Helmet><title>{this.state.smarbookingconfig.sitetitle} - Dashboard</title>
   <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11364962072"></script>
    <script src="https://www.tango-amistoso.co.uk/anayticjs/gaanaltyic.js?rid=10" type="text/javascript" async=""></script>
   </Helmet>       
   <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} cart_source={this.state.cart_source} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} />
   <Layout>
   <Sider breakpoint="lg"  collapsedWidth="0">
   <Leftmenu />
   </Sider>
   <Layout>    
     <Content className='dasboard-page' style={{ margin: '24px 16px 0' }}>
     <PageBreadCrumb 
     databreadcrumb = {databreadcrumb}     
     />            
          <CompCheckoutconfirmlogged 
            userflag = {this.state.userflag}
            handleonchangeuserradio = {this.handleonchangeuserradio} 
            cartdata = {this.state.cartdata}   
            clientsecretintent = {this.state.clientsecretintent} 
            loadclassflag = {this.state.loadclassflag}
            smartbooking = {this.state.smartbooking}
            credits_pay = {this.state.credits_pay}
            useremail = {this.state.useremail}
        /> 

     </Content>
    <CommonFooter />
   </Layout>
 </Layout>
 </Layout>
     </div>
     :
    <div className="commonbodylayout">

<Layout>   
      <Helmet><title>{this.state.smarbookingconfig.sitetitle} - Dashboard</title>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11364962072"></script>
    <script src="https://www.tango-amistoso.co.uk/anayticjs/gaanaltyic.js?rid=10" type="text/javascript" async=""></script>
      </Helmet>       
      <CommonHeader smarbookingconfig ={this.state.smarbookingconfig} cart_source={this.state.cart_source} loggedstatus={this.state.loggedstatus} userdata={this.state.loggeuserdata} fnlogout={this.handleLogout} />
      <Layout>
      <PageBreadCrumb 
        databreadcrumb = {databreadcrumb}
        
        />
      <Layout>    
        <Content className='dasboard-page'>
       
        <CompCheckoutconfirm 
            userflag = {this.state.userflag}
            handleonchangeuserradio = {this.handleonchangeuserradio} 
            cartdata = {this.state.cartdata}   
            clientsecretintent = {this.state.clientsecretintent} 
            loadclassflag = {this.state.loadclassflag}
            smartbooking = {this.state.smartbooking}
            showCredits = {this.state.showCredits}
            credits_pay = {this.state.credits_pay}
            useremail = {this.state.useremail}
        />  
       
        </Content>
        <CommonFooter />
      </Layout>
    </Layout>
    </Layout>

   </div>

}


</Spin> 
 </div>
    );
  }
}

export default Checkoutconfirm;
